export default function CloseMe() {
  return (
    <div className="bg-indigo-950 min-h-screen flex flex-col items-center justify-center">
      {/* Header */}
      <div>
        <img src={"./logo.png"} alt="Logo" className="w-40 h-40 rounded-xl" />
      </div>
      <div className="mb-8 mt-4">
        <h1 className="text-5xl text-blue-300">Cozy Intros</h1>
      </div>

      <div>
        <p className="text-xl text-blue-100">
          We successfully updated the introduction. Feel Free to Close this Tab.
        </p>
      </div>
    </div>
  );
}
