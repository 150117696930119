import axios from "axios";

export default class IntroductionService {
  apiUrl: string;
  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async isConnectionPossible(linkedInUrl: string) {
    const response = await axios.post(this.apiUrl + "/api/introduction", {
      linkedInUrl,
    });

    return response.data.result;
  }
  async submitPotentialConnection(linkedInUrl: string, reason: string) {
    const response = await axios.post(this.apiUrl + "/api/stripe/checkout", {
      linkedInUrl,
      reason,
    });

    return response.data.url;
  }
}
