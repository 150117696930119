import { useState, useEffect } from "react";
import Icon from "@mdi/react";
import {
  mdiMagnify,
  mdiEmail,
  mdiCheck,
  mdiClose,
  mdiTimerSand,
} from "@mdi/js";
import IntroductionService from "./IntroductionService";

export default function App() {
  const [linkedInUrl, setLinkedInUrl] = useState(undefined); // Track first click state
  const [introductionReason, setIntroductionReason] = useState(undefined);
  const [connectionResponse, setConnectionResponse] = useState();
  const [clicked, setClicked] = useState(false); // Track first click state
  const [error, setError] = useState(false); // Track first click state
  const [loading, setLoading] = useState(false); // Track second click for hourglass
  const [completed, setCompleted] = useState(false); // Track whether loading is complete
  const [reset, setReset] = useState(false); // Track third click (reset state)
  const introductionService = new IntroductionService(
    "https://production-api.cozyintros.com"
  );

  const handleClick = async () => {
    if (!clicked) {
      setClicked(true); // First click: expand the search bar
    } else if (!loading && !completed) {
      setLoading(true); // Second click: show loader and progress bar
      if (linkedInUrl) {
        try {
          setConnectionResponse(
            await introductionService.isConnectionPossible(linkedInUrl)
          );
        } catch (e) {
          setError(true);
        }
        setCompleted(true);
      }
    } else if (completed && !reset) {
      if (connectionResponse && linkedInUrl && introductionReason) {
        setReset(true); // Third click: reset the button and show checkmark
        const response = await introductionService.submitPotentialConnection(
          linkedInUrl as unknown as string,
          introductionReason as unknown as string
        );
        if (response) {
          // Redirect the user to the URL provided in the response
          window.location.href = response;
        }
      }

      setReset(true);
    } else if (reset) {
      setClicked(true);
      setReset(false);
      setError(false);
      setConnectionResponse(undefined);
      setLinkedInUrl("" as unknown as undefined);
      setLoading(false);
      setCompleted(false);
    }
  };

  return (
    <div className="bg-indigo-950 min-h-screen flex flex-col items-center justify-center">
      {/* Header */}
      <div>
        <img src={"./logo.png"} alt="Logo" className="w-40 h-40 rounded-xl" />
      </div>
      <div className="mb-8 mt-4">
        <h1 className="text-5xl text-blue-300">Cozy Intros</h1>
      </div>

      <div
        className={`relative top-[20px] transition-all duration-500 w-[360px]  overflow-hidden ${
          completed && connectionResponse && !reset ? "h-40" : "h-0"
        }`}
      >
        <textarea
          placeholder="Why do you want to meet with this contact?"
          id=""
          style={{ resize: "none" }}
          className="w-full h-full border-t-2 rounded-3xl pt-24 px-5 bg-blue-100 placeholder-slate-500 text-blue-600"
          onChange={(e: any) => setIntroductionReason(e.target.value)}
        ></textarea>
      </div>

      {/* Search Input and Button */}
      <div className="relative">
        {/* Input Bar */}
        <input
          value={linkedInUrl}
          disabled={completed && connectionResponse !== undefined && !reset}
          type="text"
          className={`w-20 h-20 bg-white border-4 text-blue-500 border-white rounded-full py-3 pl-8 pr-3 placeholder-gray-400 focus:outline-none focus:ring-5 focus:ring-yellow-600 transition-all duration-500 ${
            clicked && !reset ? "pl-16 w-[400px]" : ""
          } ${connectionResponse && !reset ? "transform -translate-y-[150px]" : ""}`} // Move up by 400px if connectionResponse is not null or false
          placeholder="Insert A Linkedin Profile Url Here"
          onChange={(e: any) => setLinkedInUrl(e.target.value)}
        />

        {/* Magnifying Glass Button */}
        <button
          disabled={
            (clicked && !linkedInUrl && !reset) ||
            (completed && !introductionReason && !error)
          }
          onClick={handleClick}
          className={`w-14 h-14 absolute inset-0 m-auto ${
            (completed && connectionResponse) || reset
              ? "bg-emerald-500"
              : error || (!connectionResponse && completed)
                ? "bg-red-500"
                : "bg-yellow-500"
          } 
  ${completed && !reset && connectionResponse ? "top-[-300px]" : ""}
  hover:bg-yellow-700 rounded-full flex items-center justify-center shadow-lg transition-all duration-500 ${
    clicked && !reset ? "translate-x-[160px] rotate-[360deg]" : ""
  }`}
        >
          <div className="relative w-full h-full flex items-center justify-center">
            <Icon
              path={mdiMagnify}
              size={1.5}
              className={`text-blue-950 transition-opacity duration-500 absolute ${
                loading ? "opacity-0" : "opacity-100"
              }`}
            />
            {clicked && connectionResponse === undefined && (
              <Icon
                path={mdiTimerSand}
                size={1.5}
                className={`text-blue-950 transition-opacity delay-100 duration-500 absolute icon-animation ${
                  (!loading && !completed) || error
                    ? "opacity-0"
                    : "opacity-100"
                }`}
              />
            )}
            {!reset && (
              <Icon
                path={mdiEmail}
                size={1.5}
                className={`text-blue-950 absolute transition-all delay-100 duration-00 ${
                  connectionResponse ? "opacity-100" : "opacity-0"
                }`}
              />
            )}
            {completed && !reset && (
              <Icon
                path={mdiClose}
                size={1.5}
                className={`text-red-950 absolute transition-all duration-500 ${
                  error || (!connectionResponse && completed) ? "opacity-100" : "opacity-0"
                }`}
              />
            )}
            {reset && (
              <Icon
                path={mdiCheck}
                size={1.5}
                className={`text-blue-950 absolute transition-all duration-500 ${
                  reset ? "opacity-100" : "opacity-0"
                }`}
              />
            )}
          </div>
        </button>
      </div>

      {/* Fading Message */}
      <div
        id="WeCanConnectYou"
        className={`${
          completed && connectionResponse && !reset
            ? "opacity-100"
            : "opacity-0"
        } transition-opacity duration-1000 delay-100 text-blue-800 bg-emerald-400 px-10 py-4 rounded-lg shadow-lg`}
        style={{ marginTop: "20px" }} // Position the banner below the input bar
      >
        Yes, we can connect you.
      </div>
      <div
        id="WeCannotConnectYou"
        className={`${
          completed && error && !reset ? "opacity-100" : "opacity-0"
        } transition-opacity duration-1000 delay-100 text-red-800 bg-red-400 px-10 py-4 rounded-lg shadow-lg`}
        style={{ marginTop: "20px" }} // Position the banner below the input bar
      >
        Unfortunately, we cannot connect you.
      </div>
    </div>
  );
}
